.users {
  .tabs {
    .usersView {
      padding-top: 10px;
      max-height: 60vh;
      overflow-y: auto;
      overflow-x: hidden;
      .searchCon {
        padding-inline: 0 !important;
        margin-bottom: 2vh;
        :global(.ant-input-affix-wrapper) {
          padding-inline: 10px;
        }
        * {
          border: none !important;
          box-shadow: none !important;
        }
        :global(.ant-input-affix-wrapper) {
          border-radius: 16px !important;
          overflow: hidden;
        }
        :global(.ant-input-group-addon) {
          display: none;
        }
        * > span {
          background: #f2f2f7 !important;
          border-bottom: 1px solid #e7eaee !important;
          border-radius: 16px !important;
          font-weight: 500;
          border: none !important;
          padding: 5px 0px;
          &:last-child {
            padding: 5px 8px;
          }
          input {
            font-weight: 500;
            border: none !important;
            box-shadow: none !important;
            padding-inline-start: 15px;
          }
          input::placeholder {
            background: #f2f2f7 !important;
            color: #8e8e93;
            border: none !important;
            font-weight: 500 !important;
          }
        }
      }
      .count {
        font-weight: bold;
      }
      .userItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-block: 4px;
        padding-block: 4px;
        border-bottom: 1px solid #eff1f3;
        width: 100%;
        .checkbox {
          border-radius: 50%;
          margin-inline-end: 10px;
        }
        .editButton {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid rgba(229, 229, 234, 1);
          background-color: rgba(242, 242, 247, 1);
        }
        .icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-inline-end: 10px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .info {
          flex-grow: 1;
          .name {
            font-weight: bold;
          }
          .role {
            font-size: 14px;
          }
        }
        .actions {
        }
      }
    }
    margin: 10px;
    [class~="ant-tabs-nav"] {
      position: relative;
      margin: 0;
      top: 0;
      z-index: 0;
      left: 0;
      transform: translate(0);
      background-color: transparent;
      [class~="ant-tabs-tab"] {
        flex-grow: 1;
        [class~="ant-tabs-tab-btn"] {
          //   padding: 7px;
        }
      }
      [class~="ant-tabs-nav-list"] {
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        [class~="ant-tabs-tab"] {
          justify-content: center;
          align-items: center;
          margin: 0;
          border-bottom: 2px rgba(0, 0, 0, 0.288) solid;
          transition-duration: 0.2s;
          &[class~="ant-tabs-tab-active"] {
            font-weight: bold;
            border-bottom: 2px #000000 solid;
            [class~="ant-tabs-tab-btn"] {
              background-color: transparent !important;
            }
          }
        }
      }
      [class~="ant-tabs-nav-operations"] {
        display: none !important;
      }
    }
  }
}

.roleItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  img {
    width: 24px;
    height: 24px;
    margin-inline-end: 5px;
  }
}
