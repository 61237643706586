.tabs {
  margin: 10px;
  [class~="ant-tabs-nav"] {
    position: relative;
    margin: 0;
    top: 0;
    z-index: 0;
    left: 0;
    transform: translate(0);
    background-color: transparent;
    [class~="ant-tabs-tab"] {
      flex-grow: 1;
      [class~="ant-tabs-tab-btn"] {
        //   padding: 7px;
      }
    }
    [class~="ant-tabs-nav-list"] {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      [class~="ant-tabs-tab"] {
        justify-content: center;
        align-items: center;
        margin: 0;
        border-bottom: 2px rgba(0, 0, 0, 0.288) solid;
        transition-duration: 0.2s;
        &[class~="ant-tabs-tab-active"] {
          font-weight: bold;
          border-bottom: 2px #000000 solid;
          [class~="ant-tabs-tab-btn"] {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

.collapse {
  .label {
    display: flex;
    align-items: center;
    svg,
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-inline-end: 10px;
    }
  }
  // .children {
  //   max-height: 16rem;
  //   overflow-y: auto;
  //   &::-webkit-scrollbar {
  //     width: 0.3em;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     background-color: #eee;
  //   }
  // }
}
