.audio {
  padding-top: 10px;
  .count {
    font-weight: bold;
  }
  .audioCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-block: 4px;
    padding-block: 4px;
    border-bottom: 1px solid #eff1f3;
  }
  .audioIcon {
    width: 20px;
    margin-inline-end: 10px;
  }
  .audioInfo {
    flex-grow: 1;
    .audioName {
      font-weight: bold;
    }
    .info {
      font-size: 12px;
    }
  }
  a {
    .downFileIcon {
    }
  }
}
