.rolesButtons {
  .rolesButton {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    background-color: transparent;
    padding: 10px 15px;
    text-align: start;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #a7a7a7;
    font-size: 16px;
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-inline-end: 15px;
    }
    &.active {
      background: linear-gradient(
        223.01deg,
        #bf40341a -5.79%,
        #9b17241a 109.35%
      );
      border: 1px solid #9b1724;
    }
  }
}
