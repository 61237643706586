.play_pause {
  --size: 40px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 2px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * 3.14 * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  margin: 0;
  margin-inline-end: 10px;
  padding: 0;
  border: none;
  width: var(--size);
  height: var(--size);
  border-radius: var(--radius);
  position: relative;
  cursor: pointer;
  &:focus,
  &:hover {
    border: none;
  }
}

.play_pause div {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circular_progress {
  animation: progress-animation 5s linear 0s 1 forwards;
}

.circular_progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular_progress circle.bg {
  stroke: #ddd;
}

.circular_progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: #9b1724;
}

@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}
