@use "../base/_colors.scss" as *;
@use "../base/_typography.scss" as *;
@use "../components/_sliders.scss" as *;
@use "../base/media-query" as *; // Import the responsive SCSS file

.auth__container {
  .part {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include xl {
      width: 100%;
      &:last-child {
        display: none;
      }
    }
  }

  form {
    width: 450px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   
    p {
      color: $light-grey;
    }
    label {
      font-weight: 600;
    }
    input {
      /* Frame 3 */

      box-sizing: border-box;

      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px !important;
      gap: 8px;

      background: #f7f8f9;
      border-bottom: 1px solid #e7eaee;
      border-radius: 12px;

      /* Inside auto layout */
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
    * {
      text-align: left;
    }
    *:not(svg, span) {
      width: 100%;
    }
    *::before {
      display: none !important;
    }
  }

  .img__otp {
    width: 120px !important;
  }

  p {
    margin: 5px;
  }

  button {
    width: 100%;
  }
}

:global {
  .ant-input-suffix {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    z-index: 1;
    width: 0px;
  }

  .ant-otp .ant-otp-input {
    width: 65px;
    height: 65px;
  }
  .otp__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .otp__wrapper * {
    text-align: center;
  }
  @media only screen and (max-width: 600px) {
    .ant-otp .ant-otp-input {
      width: 50px;
      height: 50px;
    }
  }
}
