@tailwind base;
@tailwind components;
@tailwind utilities;

/**********
 * GENRAL *
 **********/
body {
  margin: 0;
  background-color: #f1f2f4;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

/********
   * WRAP *
   ********/
.wrapper__flex {
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/**********
   * MARGIN *
   **********/

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.m-5 {
  margin: 3rem;
}

.m-b {
  margin: 7.5rem;
}

/**********
   * BUTTON *
   **********/
.main__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: linear-gradient(
    223.01deg,
    #bf4034 -5.79%,
    #9b1724 109.35%
  ) !important;
  color: white !important;
  border-radius: 12px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  padding: 25px !important;
  border-radius: 15px;
}

/**********
   * LOADER *
   **********/

/* styles.css or Loader.css */

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*********
 * CALLS *
 *********/
/* In order to handle the incoming  calls */
.call__container {
  font-family: Lato;
  font-size: 16px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #636366;
  color: white;
  z-index: 99;
  position: absolute;
  top: 0;
  bottom: 0;
}

.call__container .bkw-xlarge-heading {
  font-size: 7rem;
  margin: 0 0 0.5em;
  font-weight: 300;
  letter-spacing: -0.04em;
  line-height: 7rem;
}
.call__container .bkw-thin-large-heading {
  font-size: 3.5rem;
  font-weight: 300;
  margin: 0 0 1em;
  letter-spacing: -0.02em;
  line-height: 3.5rem;
}
.call__container h1 {
  font-size: 3rem;
  font-weight: 300;
  margin: 0 0 1em;
  line-height: 3rem;
}
.call__container h2 {
  font-size: 2.25rem;
  font-weight: 400;
  margin: 0 0 1em;
  line-height: 3rem;
}
.call__container h3 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 1em;
  line-height: 2rem;
}
.call__container h4 {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0 0 1em;
  letter-spacing: 0.04em;
  line-height: 2rem;
}
.call__container .subheading {
  font-size: 1.125rem;
  margin: 0 0 1em;
  letter-spacing: 0.04em;
  line-height: 1.5rem;
}
.call__container .caption {
  font-size: 0.875rem;
  margin: 0 0 1em;
  line-height: 1.125rem;
}
.call__container p {
  margin: 0 0 1em;
}
.call__container .danger {
  background-color: #f65959;
}
.call__container .success {
  background-color: #99d154;
}
.call__container [class^="btn-"].danger:hover {
  background-color: shade(#f65959, 12%);
}
.call__container [class^="btn-"].success:hover {
  background-color: shade(#99d154, 12%);
}
.call__container .caller-info {
  min-height: 360px;
}

.call__container .caller-info .avatar-caller img {
  position: absolute;
  z-index: 2;
  width: inherit;
  height: inherit;
}
.call__container .call-buttons {
  width: 400px;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.call__container .call-buttons .btn-floating-label .btn-floating.btn-jumbo {
  width: 7rem;
  height: 7rem;
  border-radius: 50px;
  border: 1px solid black;
}
.call__container .call-buttons .btn-floating-label .btn-floating.btn-jumbo i {
  font-size: 3rem;
  line-height: 7rem;
}
.call__container .call-buttons .btn-floating-label h4 {
  font-weight: 300;
  margin-top: 0.5rem;
  text-align: center;
}

.call__container .call-buttons .btn-floating-label span {
  font-size: 60px;
}
.call__container #reject span {
  transform: rotate(225deg);
}
@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
.call__container .ripple-wave {
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  height: 240px;
  width: 240px;
}
.call__container .ripple-wave::before,
.call__container .ripple-wave::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  will-change: transform, opacity;
}
.call__container .ripple-wave::after {
  animation: pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  transition: opacity 0.4s, transform 0.4s;
}
.call__container .ripple-wave .ripple-wave-2 {
  position: absolute;
  height: inherit;
  width: inherit;
}
.call__container .ripple-wave .ripple-wave-2::before {
  content: "";
  display: block;
  height: inherit;
  width: inherit;
  border-radius: 50%;
  background-color: white;
  animation: pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  animation-delay: 0.24s;
}

/*********
 * MODAL *
 *********/

/* In order to handle the modals */

.ant-modal .ant-modal-content {
  height: 100%;
}
