.swiper {
  height: 90vh;
  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  img {
    width: auto;
    height: 100%;
  }
}
