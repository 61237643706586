@use "styles/base/_colors.scss" as *;
@use "styles/base/_typography.scss" as *;
@use "styles/components/_sliders.scss" as *;
@use "styles/components/modals" as *;

.messageNotification {
  .ant-notification-notice-message {
    margin-inline-start: 65px !important;
    font-weight: 600;
  }

  .ant-notification-notice-description {
    margin-inline-start: 65px !important;
    font-size: 12px;
    font-weight: 500;
    p{
    font-size: 12px;

    }
  }
}

.ant-spin-fullscreen{
  background-color: rgb(247, 247, 247);
}

.uppercaseSpaces {
  // capitalize Spaces
  text-transform: uppercase !important;
}