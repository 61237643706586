@use "../base/_colors.scss" as *;
@use "../base/_typography.scss" as *;
@use "../components/_sliders.scss" as *;
@use "../base/media-query" as *; // Import the responsive SCSS file

.nav {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  max-height: 100px;
  height: 75px;
  border-bottom: 1px solid #e7eaee;
  background: white;
  position: fixed;
  z-index: 9;
  width: 100%;
  padding-inline: 40px;
  @include xl {
    height: 100px;
  }

  .part__1,
  .part__2,
  .part__3 {
    display: flex;
    align-items: center;
  }

  .part__1 {
    width: 33.333%;
    @include xl {
      width: 60%;
    }
  }

  .part__2 {
    width: 33.333%;

    @include xl {
      width: 60%;
    }
  }

  .part__1 {
    // justify-content: space-around;
    div {
      margin: 0;
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 10px;
      @include xl {
        margin: 0 25px;
      }
    }

    form {
      width: 350px;
    }
  }

  .part__2 {
    @include xl {
      display: none !important;
    }
   
    ul {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      align-items: center;
      gap: 30px;
      justify-content: center;
      li {
        // margin-inline-end: 30px;
        position: relative;
        .notificationCount{
          background-color: #9b1724;
          border-radius: 50%;
          font-size: 10px !important;
          color: #fff;
          width: 15px;
          height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -5px;
          inset-inline-start: -5px;
          filter: initial !important;
          z-index: 5;
        }
        a {
          width: 100%;
          display: flex;
          align-items: center;
         
          svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }

          filter: invert(39%) sepia(0%) saturate(1632%) hue-rotate(78deg)
            brightness(94%) contrast(84%);

          span {
            color: #636366;
            font-size: 15px !important;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .part__3 {
    width: 33.33%;
    justify-content: end;
    @include xl {
      width: unset;
    }

    ul {
      border-bottom: none;

      li {
        &::after {
          border-bottom: none !important;
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.nav__mobile {
  display: none !important;
  z-index: 9;

  @include xl {
    display: block !important;
  }
  // VARIABLES
  // Colors
  $canvasBg: rgb(255, 226, 216);
  $frameColor: rgb(247, 247, 247);
  $iconColor: rgb(51, 51, 51);
  $bgColorWrap: ("#9b1724", "#9b1724", "#9b1724", "#9b1724", "#9b1724");

  // Sizes
  $frameWidthMobile: 340px;
  $iconSize: 33px;

  // Structure
  $wrapWidth: (
    $iconSize * 4,
    $iconSize * 4,
    $iconSize * 4,
    $iconSize * 4,
    $iconSize * 4
  );

  // FUNCTIONS
  @function em($pixel, $number: 16) {
    @return #{$pixel/$number}em;
  }

  // STRUCTURE
  // Body and Frame
  display: flex;
  justify-content: center;
  align-items: center;
  background: $canvasBg;
  position: fixed;
  bottom: 0;
  width: 100%;

  // Bar
  .bar {
    width: 100%;
    height: 70px;
    background: #fff;
    box-shadow: 0 10px 40px rgba(51, 51, 51, 0.4);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-around;
    flex-direction: row;
  }

  // Icons
  .icon__wrap {
    display: flex;
    align-items: center;
    width: $iconSize;
    background-size: 1200px;
    background-position: 100% 50%;
    color: $iconColor;
    padding: 0 9px;
    border-radius: 100px;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
    transition: width 200ms, padding 200ms, border-radius 300ms,
      background-position 900ms;
    transition-timing-function: ease-in-out;

    &.nav__mobile__active {
      // width: $iconSize *2.5;
      background-position: 0 50%;
      padding: 3px 13px;
      border-radius: 50px;
      .icon {
        font-size: $iconSize - 6;
        margin-right: 10px;
      }
      .label {
        opacity: 1;
      }
    }
    &.nav__mobile__active {
      // width: $iconSize *2.5;
      background-position: 0 50%;
      padding: 3px 13px;
      border-radius: 50px;
      .icon {
        font-size: $iconSize - 6;
        margin-right: 10px;
      }
      .label {
        opacity: 1;
      }
    }

    .icon {
      font-size: $iconSize;
      margin-right: 25px;
      position: relative;
      z-index: 2;
      transition: font-size 250ms, margin-right 200ms;
      transition-timing-function: ease-out;
    }
    .label {
      font-weight: 600;
      letter-spacing: 0.15em;
      opacity: 0;
      position: relative;
      z-index: 2;
      font-size: $iconSize/3;
      transition: opacity 400ms;
      transition-delay: 130ms;
      transition-timing-function: ease-out;
    }
  }

  // Icons Loops
  @for $i from 0 to length($bgColorWrap) {
    .el__#{$i} {
      background-image: radial-gradient(
        circle at right,
        #fff,
        unquote(nth($bgColorWrap, $i + 1))
      );
    }
  }
  @for $i from 0 to length($wrapWidth) {
    .el__#{$i}.nav__mobile__active {
      width: nth($wrapWidth, $i + 1);
    }
    .el__#{$i}.nav__mobile__active {
      width: nth($wrapWidth, $i + 1);
    }
  }
}

.nav__item__menu {
  min-width: 250px;

  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: unset !important;
  position: relative;
  svg:first-child {
    margin: 10px;
  }

  svg:last-child {
    margin: 10px;
    position: absolute;
    right: 0;
  }
}
:global {
  .nav__container {
  }

  .nav__active * {
    filter: invert(10%) sepia(54%) saturate(6772%) hue-rotate(346deg)
      brightness(102%) contrast(90%) !important;
    color: #9b1724 !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    &:after {
      position: absolute;
      content: "";
      height: 2px;
      background-color: #9b1724;
      width: 50%;
      margin-left: 15px;
      /* top: 50%; */
      bottom: -27px;
      left: -55px;
      width: 190%;
    }
  }

  .ant-input-suffix {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    z-index: 1;
    width: 0px;
  }
  // .ant-input-outlined {
  //   background-color: #f7f8f9;
  //   border-radius: 15px;
  //   border: none;
  //   box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 3px, rgba(0, 0, 0, 0.17) 0px 1px 2px;
  //   padding: 10px;
  // }
  .ant-otp .ant-otp-input {
    width: 65px;
    height: 65px;
  }
  .otp__wrapper {
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    .ant-otp .ant-otp-input {
      width: 50px;
      height: 50px;
    }
  }
}

.menu__account {
  background-color: white;
  // min-width: 200px;
  @include xl {
    min-width: 50px;
  }
}
