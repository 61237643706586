.media {
  padding-top: 10px;
  .count {
    font-weight: bold;
    margin-bottom: 5px;
  }
  :global(.ant-image) {
    width: 100% !important;
  }
}
