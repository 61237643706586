.infoCon {
  background-color: #fff;
  height: 77vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
  }
  .general {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px 10px;
    .icon {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      margin-block-end: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      font-size: 20px;
      font-weight: bold;
    }
    .description {
    }
  }
  .notif {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &_lable {
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        margin-inline-start: 5px;
      }
    }
  }
  .tabs {
    padding: 10px;
    .infoTabs {
      [class~="ant-tabs-nav"] {
        position: relative;
        margin: 0;
        top: 0;
        z-index: 0;
        left: 0;
        transform: translate(0);
        [class~="ant-tabs-tab"] {
          flex-grow: 1;
          [class~="ant-tabs-tab-btn"] {
            padding: 7px;
            width: 100%;
            text-align: center;
          }
        }
        [class~="ant-tabs-nav-list"] {
          margin: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
}
