@import "/src/styles/base/colors";

.createbtn {
  background: $primary-color;
  font-size: 12px;
  padding: 7px 22px;
  border-radius: 12px;
  height: auto;
  &:hover {
    background: $primary-color !important;
    opacity: 0.8;
  }
}
.cancelbtn {
  background: rgba(242, 242, 247, 1);
  font-size: 12px;
  padding: 7px 22px;
  border-radius: 12px;
  height: auto;
  &:hover {
    background: rgba(242, 242, 247, 0.8) !important;
  }
}
.spaceCreateForm {
  .chatUsers {
    height: 80vh;
    overflow-y: auto;
    .userCard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-block-end: 10px;
      .icon {
        width: 40px;
        height: 40px;
        margin: 5px;
        margin-inline-end: 10px;
        img {
          border-radius: 50%;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .info {
        flex-grow: 1;
        .name {
          font-weight: bold;
        }
        .count {
          font-size: 14;
        }
      }
      .actions {
        display: flex;
        flex-direction: row;
        gap: 8px;
        .editBtn,
        .delBtn {
          border: none;
          display: flex;
          align-items: center;
          flex-direction: row;
          border-radius: 10px;
        }
        .delBtn {
          background-color: #ffebea;
        }
        .editBtn {
          background-color: #f2f2f7;
        }
      }
    }
    .searchCon {
      // padding-inline: 8% !important;
      margin-bottom: 1vh;
      :global(.ant-input-affix-wrapper) {
        padding-inline: 10px;
      }
      * {
        border: none !important;
        box-shadow: none !important;
      }
      :global(.ant-input-affix-wrapper) {
        border-radius: 16px !important;
        overflow: hidden;
      }
      .ant-input-group-addon {
        display: none;
      }
      * > span {
        background: #f2f2f7 !important;
        border-bottom: 1px solid #e7eaee !important;
        border-radius: 16px !important;
        font-weight: 500;
        border: none !important;
        padding: 5px 0px;
        &:last-child {
          padding: 5px 8px;
        }
        input {
          font-weight: 500;
          border: none !important;
          box-shadow: none !important;
          padding-inline-start: 15px;
        }
        input::placeholder {
          background: #f2f2f7 !important;
          color: #8e8e93;
          border: none !important;
          font-weight: 500 !important;
        }
      }
    }
  }
}

.addspaceModal {
  .ant-modal-confirm-title {
    padding-block: 10px;
  }
  .ant-modal-content {
    border-radius: 32px;
    .ant-upload {
      width: 100% !important;
      .uploadbtncon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
      }
      .placeHolderImg {
        width: 65px;
        height: 65px;
        object-fit: cover;
        border-radius: 50%;
        margin-inline-end: 10px;
      }
      .uploadtext {
        flex-grow: 1;
        text-align: start;
      }
      .uploadbtn {
        color: #9b1724;
        display: flex;
        justify-content: center;
      }
    }
    .ant-select-outlined {
      background-color: #f7f8f9;
      border-radius: 15px;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 3px,
        rgba(0, 0, 0, 0.17) 0px 1px 2px;
      padding: 6px;
      .ant-select-selector {
        border: none;
        background: transparent;
        &:active {
          border: none;
        }
      }
    }
  }
  .editinglog__steps {
    .ant-steps-item-container {
      .ant-steps-item-tail {
        inset-inline-start: 1px !important;
        padding: 0 !important;
        &::after {
          width: 4px !important;
          margin-inline-start: 4.5px !important;
        }
      }
    }

    .ant-steps-item {
      &:hover * {
        color: unset !important;
      }
      &:hover p,
      &:hover .editedby__log {
        color: white !important;
      }
      &:hover div {
        color: #636366 !important;
      }
    }

    .ant-steps-item-icon {
      width: 15px !important;
      height: 15px !important;
      gap: 0 !important;
      border-radius: 50% !important;
      inset-inline-start: 0 !important;
      position: relative !important;
      border: 3px #f2f2f7 solid !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      background-color: #d1d1d6 !important;
    }
    .date__log {
      color: #636366 !important;
      font-size: 13px !important;
      text-transform: capitalize;
      &:hover {
        color: #636366 !important;
      }
    }
    .messagebox__log {
      background: linear-gradient(223.01deg, #bf4034 -5.79%, #9b1724 109.35%);
      border-radius: 12px 0px 12px 12px;
      padding: 1.3vh 14px 6vh;
      position: relative;
      min-width: 45%;
      width: fit-content;
      position: relative;
      text-align: start;
      p {
        color: white;
        line-height: 10px;
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 24px;
      }
      .editedby__log {
        color: white;
        font-size: 10px;
        font-weight: 500;
        position: absolute;
        bottom: 6px;
        inset-inline-start: 10px;
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .searchMessageCon {
    padding: 20px 0;
    .notFound {
      text-align: center;
      padding: 7px 20px;
      border-radius: 12px;
      margin-top: 10px;
      width: fit-content;
      margin: 14vh auto;
    }
    .searchMessage {
      position: relative;
      display: flex;

      .searchUser {
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
          margin-inline-end: 15px;
          background-color: white;
        }
      }

      .searchMessageText {
        width: 100%;
        p {
          background: linear-gradient(
            223.01deg,
            #bf4034 -5.79%,
            #9b1724 109.35%
          );
          color: white;
          border-radius: 0px 12px 12px 12px;
          margin-bottom: 20px;
          padding: 20px 20px 5px;
        }
      }
      .messageDate {
        text-align: end;
        font-size: 12px;
      }
    }
  }
}

.addUserSuccesfuly {
  .ant-modal-confirm-title {
    padding-block: 10px;
  }
  .ant-modal-content {
    border-radius: 32px;
    .userAddedCon {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-bottom: 3vh;
      }
      h3 {
        color: #1c1c1e;
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 2vh;
      }
      p {
        color: #8e8e93;
        text-transform: capitalize;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 12px !important;
        margin-bottom: 0 !important;
      }
      h1 {
        color: #1c1c1e;
        font-weight: 700;
        font-size: 21px;
        margin-bottom: 8vh;
      }
      button {
        border: none !important;
        padding: 14px 44px;
        border-radius: 12px;
        background-color: #f2f2f7;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        color: #3a3a3c;
        img {
          margin: 0;
          margin-inline-start: 15px;
        }
      }
    }
  }
}
.MyContactList {
  .ant-modal-confirm-body {
    justify-content: center;
  }
  .ant-modal-content {
    background-color: #ffffff;
    border-bottom: 1px solid #e5e5ea;
  }
  .ant-modal-confirm-content {
    max-width: initial !important;
  }
  .ant-modal-confirm-title {
    display: inline !important;
    width: fit-content !important;
    flex: initial !important;
    margin-bottom: 20px;
  }
  .anticon-info-circle {
    display: none !important;
  }
  .ant-modal-confirm-body,
  .ant-modal-confirm-content {
    margin-inline: 0 !important;
  }
  .ant-btn-primary {
    background: linear-gradient(126deg, #601ef9 0%, #7b51ff 93.2%);
    border: none !important;
  }
  .modalheader {
    .modalTitle {
      font-size: 18px;
      font-weight: 700;
      color: #1c1c1e;
    }
    .modalSubtitle {
      font-size: 13px;
      color: #999 !important;
    }
  }
  .ant-checkbox-group {
    display: block;
    width: 100%;
  }
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      flex: 50%;
      color: #1c1c1e !important;
      justify-content: center;
    }
  }
  .chatUsers {
    // border-inline-end: 4px solid $grey;/
    height: 100%;
    padding-block: 30px;
    padding: 0;
    width: 100%;
    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #efefef;
      border-radius: 30px;
    }
    .userCard {
      padding: 10px 30px 10px 25px;
      display: flex;
      align-items: start;
      justify-content: space-between;
      cursor: pointer;

      &:last-child {
        border: none;
      }
      .cardImgCon {
        margin-inline-end: 15px;
        .imgPlaceHolder {
          width: 50px;
          height: 50px;
          border-radius: 16px;
          // background-color: #809fb8;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            object-fit: cover;
          }
          .cardId {
            color: white;
            font-size: 12px;
            font-family: "Medium";
          }
          .online {
            width: 13px;
            height: 13px;
            background-color: #80ea4e;
            border-radius: 50%;
            border: 3px solid white;
            position: absolute;
            bottom: 0px;
            inset-inline-end: 0px;
          }
        }
      }
      .cardText {
        .cardTitle {
          font-family: "Bold";
          color: black;
          font-size: 16px;
          margin-bottom: 3px;
        }
        .cardDes {
          color: #a7a9ac;
          font-size: 12px;
        }
      }
      .cardDetails {
        margin-inline-start: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: end;
        .cardTime {
          color: #06152b;
          font-family: "Bold";
          margin-bottom: 10px;
          margin-top: 5px;
          color: #a7a9ac;
        }
        .messageCount {
          background-color: blue;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-bottom: 5px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-family: "Medium";
        }
      }
      .editButton {
        border-radius: 1111px;
        border: 1px solid #48484a;
        background: #3a3a3c;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        margin-inline-end: 20px;
        img {
          margin-inline-end: 10px;
          width: 16px;
        }
      }
      .ant-checkbox {
        &::after {
          content: initial;
        }
        .ant-checkbox-inner {
          border-radius: 50%;
          border: 1px solid #48484a;
          background: #3a3a3c;
          padding: 11px;
          border: none !important;
          box-shadow: none !important;
          &::after {
            margin-inline-start: 2px;
          }
        }
        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #34c759;
            border: 1px solid #34c759;
          }
        }
      }
      &.active {
        background-color: rgba($color: #34c759, $alpha: 0.05);
        border-inline-end: 5px solid #34c759;
      }
    }
  }
  .ConfirmButton {
    margin-top: 30px;
    border: none !important;
    box-shadow: none !important;
    width: fit-content;
    font-size: 14px;
    padding-block: 7px;
    padding-inline: 15px;
    height: auto !important;
    margin-top: 3vh;
    margin-bottom: 3vh;
    border-radius: 10px;
    background: linear-gradient(
      223.01deg,
      #bf4034 -5.79%,
      #9b1724 109.35%
    ) !important;
    margin-left: auto;
    display: block;
    span {
      font-weight: 600 !important;
      font-size: 14px;
    }
  }
  .ant-modal-confirm-btns {
    .ant-btn-primary {
      border: none !important;
      box-shadow: none !important;
      width: 100%;
      font-size: 15px;
      padding-block: 10px;
      height: auto;
      margin-top: 0vh;
      border-radius: 10px;
      border: 1px solid #48484a !important;
      background: #3a3a3c;
      width: 100%;
      span {
        font-family: "Bold" !important;
      }
    }
  }
  .permissionList {
    .permissionCard {
      .ant-switch.ant-switch-checked {
        background: #34c759;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;
      background-color: #f2f2f7;
      border: 1px solid #e5e5ea;
      padding: 20px 20px;
      margin-bottom: 10px;
      font-family: "Bold";
      img {
        width: 27px;
        height: 27px;
        object-fit: contain;
        margin-inline-end: 20px;
        filter: grayscale(1) brightness(50%);
      }
    }
  }
}
