// Import Manrope font
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");

// Typography variables
$base-font-size: 16px; // Base font size
$heading-font-family: "Manrope", sans-serif; // Heading font family
$body-font-family: "Manrope", sans-serif; // Body font family

// Typography mixins
@mixin font-face($weight, $style: normal) {
  font-family: "Manrope", sans-serif;
  font-weight: $weight;
  font-style: $style;
}

// Typography styles
*:not(i) {
  font-family: $body-font-family;
}

body {
  font-family: $body-font-family;
  font-size: $base-font-size;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-weight: bold; // Adjust as needed
  margin: 0;
}

h1 {
}

h2 {
  font-size: 33px; // Adjust as needed
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

p,
button {
  font-size: 16px !important;
  margin: 0;
}

// Add additional typography styles as needed
