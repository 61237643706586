@use "../base/_colors.scss" as *;
@use "../base/_typography.scss" as *;
@use "../components/_sliders.scss" as *;
@use "../base/media-query" as *; // Import the responsive SCSS file

/*****************************************
 * PROFILE & CREATE NEW USER & EDIT USER *
 *****************************************/

.profile__form__container {
  width: 100%;
  display: flex;
  justify-content: center;
  :global(.ant-input-outlined) {
    background-color: #f7f8f9 !important;
    border-radius: 16px;
    border: none;
    padding-inline: 15px;
    padding-block: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px, rgba(0, 0, 0, 0.1) 0px 1px 2px;
  }
  :global(.ant-input-affix-wrapper) {
    border-radius: 10px !important;
    overflow: hidden;
  }
  :global(.ant-input-group-addon) {
    display: none;
  }
  input {
    margin-inline-start: 10px;
    font-weight: 500;
  }
  .contact__container {
    height: 70vh;
    padding: 24px 0px 0px 0px;
    gap: 24px;
    border-radius: 12px 0 0 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #e5e5ea;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $primary-color;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1c1c1e;
    }

    .perm__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;
      padding-bottom: 10px;
      padding-top: 10px;

      .part__1 {
        width: 50%;
        display: flex;
        align-items: center;
        .name {
          font-size: 16px;
          font-weight: 400;
          line-height: 21.86px;
          text-align: left;
          margin-left: 10px;
        }
      }

      .part__2 {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: end;
        :global {
          .ant-checkbox {
            border-radius: 50%;
            margin-inline-start: 15px;
            .ant-checkbox-inner {
              border-radius: 50%;
              &::after {
                margin-inline-start: 1.5px;
              }
            }
          }
        }
        button {
          width: 179.13px;
          height: 36.13px;
          padding: 8px 16px 8px 16px;
          gap: 10px;
          border-radius: 8px;
          border: 1px 0px 0px 0px;
          // opacity: 0px;
          background-color: #e5e5ea;
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 12px;
          font-weight: 600;
          line-height: 16.39px;
          text-align: left;
        }

        label {
          margin: 0 !important;

          input {
            width: 24px;
            height: 24px;
          }

          span {
            width: 24px;
            height: 24px;
          }
        }
      }

      border-bottom: 1px solid #f2f2f7;
    }

    .search__step__two {
      margin: 0 auto;
      width: 90%;
    }

    ul {
      li {
        margin: 0 auto;
        width: 90%;
      }
    }
  }

  .profile__form {
    width: 50%;

    @include xl {
      width: 80%;
    }

    label {
      //styleName: Paragraph/02/SemiBold;
      font-size: 16px;
      font-weight: 600;
      line-height: 21.86px;
      text-align: left;
      color: #1c1c1e;
      margin-top: 15px;
    }

    h2 {
      color: #1c1c1e;
      flex: none;
      order: 0;
      flex-grow: 0;
      font-size: 19px;
      font-weight: 700;
      line-height: 25.95px;
      letter-spacing: -0.02em;
      text-align: left;
    }

    .account__picture {
      margin: 30px 0;
      padding: 20px 16px 20px 16px;
      gap: 20px;
      border-radius: 16px 16px 16px 16px;
      border: 0px 0px 1px 0px;
      border-bottom: 1px solid #e7eaee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--Primary-White, #ffffff);
      @include xl {
        flex-direction: column;
      }
      div {
        &:first-child {
          display: flex;
          justify-content: center;
          align-items: center;

          h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 21.86px;
            text-align: left;
            text-wrap: nowrap;
            margin: 0px 10px;
          }
        }
        &:last-child {
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            gap: 8px;
            border-radius: 8px;
            border: none;

            //styleName: Paragraph/02/SemiBold;
            font-size: 16px;
            font-weight: 600;
            line-height: 21.86px;
            text-align: left;
            color: #9b1724;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
          }
        }
      }
    }

    input {
      &:disabled {
        border-radius: 15px;
        color: unset;
        border-color: unset;
        box-shadow: unset;
        cursor: not-allowed;
        opacity: 1;
        background-color: #ffffff;
      }
    }

    .input__account {
      span {
        padding: 0;
      }
      button {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        border: none;
        background-color: $grey2;
        border-radius: 0 15px 15px 0;
        z-index: 1;
      }
      * {
        box-shadow: none;
      }

      margin: 10px 0;
      input {
        height: 56px;
        padding: 10px;
      }
    }

    .input__date__account {
      box-shadow: none;
      border: none;
      height: 56px;
      width: 100%;
      background-color: $light-bg;
    }

    .input__phone {
      input {
        width: 100%;
        background-color: $light-bg;
        height: 56px;
        box-shadow: none;
        border: none;
        border-radius: 0 15px 15px 0;
      }

      button {
        border-radius: 15px 0 0 15px;
        height: 56px;
        box-shadow: none;
        border: none;
        width: 112px;
        min-width: 40px;
        background-color: $grey2;
      }
    }

    .profile__button {
      width: 170px;
      margin-top: 40px;
      // margin-left: auto;
      margin-bottom: 5vh;
    }
    .reset__button {
      width: 170px;
      margin-top: 40px;
      // margin-left: auto;
      margin-bottom: 5vh;
      background: transparent !important;
      border: 2px solid #a8343c !important;
      color: #a8343c !important;
      span{
        font-weight: 500;
      }
    }
  }
}

.steps__container {
  display: flex;
  justify-content: center;
  .steps {
    margin: 50px 0;

    &:hover * {
      cursor: unset !important;
    }

    * {
      padding-inline-start: 0 !important;
    }

    div[role="button"] {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    > div:first-child div[role="button"] {
      width: 50%;
    }
  }
}

:global(.ant-form-item-control-input-content) {
  justify-content: center;
  display: flex;
}
.pageHeader {
  padding: 20px 25px;
  background-color: white;
  font-weight: 600;
  color: #0d0f11;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  button {
    border: none !important;
    background-color: transparent;
    padding: 0;
    margin-inline-end: 20px;
    svg {
      width: 20px;
    }
  }
}
.editUser {
}

:global {
  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    height: 2px !important;
    background-color: #e5e5ea !important;
    border-radius: 50px;
  }

  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    height: 2px !important;
    background-color: #9b1724 !important;
    border-radius: 50px;
  }
}

.select__container {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  gap: 5px;
  @include xl {
    flex-wrap: wrap;
  }

  label {
    @include xl {
      flex: calc(25% - 5px);
      margin: 25px;
    }

    height: 56px;
    padding: 20px 16px 20px 16px;

    border-radius: 12px !important;
    border: 1px solid #e5e5ea;
    margin: unset;
    margin-top: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      inset-block-start: unset !important;
      inset-inline-start: unset !important;
      display: unset !important;
      box-sizing: unset !important;
      width: unset !important;
      height: unset !important;
      padding-block: unset !important;
      padding-inline: unset !important;
      background-color: unset !important;
      transition: background-color unset !important;
      content: unset !important;
    }
  }
}

/****************************************
 * IN ORDER TO EDIT GLOBAL ANDT CLASSES *
 ****************************************/
:global {
  .ant-steps-item {
    &:hover * {
      color: #9b1724 !important;
    }
  }

  .ant-steps-item-title {
    //styleName: Paragraph/02/SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.86px;
  }

  //styleName: Paragraph/01/Regular;

  .ant-steps-item-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
  }

  .ant-steps-item-icon {
    width: 44px !important;
    height: 44px !important;
    gap: 8px !important;
    border-radius: 32px !important;
    border: 1px $light-grey solid !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: $mid-light-bg !important;
    span {
      color: $text-color !important;
      //styleName: Heading/H5-Small/Bold;
      font-size: 19px;
      font-weight: 700;
      line-height: 25.95px;
      letter-spacing: -0.02em;
      text-align: center;
    }
  }
}
:global {
  .ant-radio-button-wrapper-checked {
    * {
      color: #9b1724;
    }

    border: 1px solid #9b1724 !important;
    background-color: #9b172410 !important;
  }
}

/****************
 * USERS LAYOUT *
 ****************/

.spaces {
  height: 100%;
  border: 1px solid $light-bg;
  padding: 5vh 0 0 8%;
  box-sizing: border-box;
  position: relative;
  background-color: white;
  .spacesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline-end: 10%;
    margin-bottom: 3vh;
    .title {
      color: $text-color;
      font-size: 18px;
    }
    .sortbyBtn {
      width: fit-content;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      font-size: 14px !important;
      font-weight: 600;
      cursor: pointer;
      svg {
        margin-inline-end: 5px;
      }
    }
  }

  .spacesBtns {
    height: 54vh;
    overflow-y: auto;
    padding-bottom: 20vh;
    text-transform: capitalize;
    &::-webkit-scrollbar {
      width: 0.3em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eee;
    }

    button {
      border-radius: 12px 0px 0px 12px;
      padding: 10px;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      width: 100% !important;
      cursor: pointer;
      svg,
      img {
        margin-inline-end: 10px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
      & > div {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        text-align: start;
        margin-inline-end: 4px;
      }
      .counter {
        background-color: #f7f8f9;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 50%;
        font-weight: 600;
        margin-inline-end: 2%;
      }
      &.active {
        background-color: $light-bg;
        .counter {
          background-color: white;
        }
      }
    }
  }
  .spacesManagment {
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    padding: 1vh 10px 2vh 10%;
    background-color: white;
    width: 100%;
    button {
      border-radius: 12px 0px 0px 12px;
      padding: 8px 10px;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
      cursor: pointer;
      svg,
      img {
        margin-inline-end: 10px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
      & > div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
      }
      .counter {
        background-color: #f7f8f9;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 50%;
        font-weight: 600;
        margin-inline-end: 5%;
      }
      &.active {
        background-color: $light-bg;
      }
    }
  }
  @include xl {
  }
}
.groups {
  height: 100%;
  background-color: white;
  border-inline-end: 1px solid #e7eaee;
  padding-top: 6vh;
  box-sizing: border-box;
  flex-grow: 1;
  .groupsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vh;
    padding-inline: 8%;
    .title {
      color: #8e8e93;
      font-weight: 600;
      font-size: 12px;
      // text-transform: uppercase;
    }

    button {
      border: none;
      background-color: transparent;
      width: fit-content;
      font-size: 12px !important;
      color: #555d68;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.red {
        color: #9b1724;
      }
      svg {
        margin-inline-end: 2px;
      }
    }
  }

  .searchCon {
    padding-inline: 8% !important;
    margin-bottom: 1vh;
    :global(.ant-input-affix-wrapper) {
      padding-inline: 10px;
    }
    * {
      border: none !important;
      box-shadow: none !important;
    }
    :global(.ant-input-affix-wrapper) {
      border-radius: 16px !important;
      overflow: hidden;
    }
    :global(.ant-input-group-addon) {
      display: none;
    }
    * > span {
      background: #f2f2f7 !important;
      border-bottom: 1px solid #e7eaee !important;
      border-radius: 16px !important;
      font-weight: 500;
      border: none !important;
      padding: 5px 0px;
      &:last-child {
        padding: 5px 8px;
      }
      input {
        font-weight: 500;
        border: none !important;
        box-shadow: none !important;
        padding-inline-start: 15px;
      }
      input::placeholder {
        background: #f2f2f7 !important;
        color: #8e8e93;
        border: none !important;
        font-weight: 500 !important;
      }
    }
  }
  .emptypage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;
    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
      margin-bottom: 10px;
      filter: grayscale(100%);
    }
    h4 {
      margin-bottom: 6px;
      font-size: 15px !important;
    }
    p {
      font-size: 12px !important;
      color: #393e46;
      margin-bottom: 15px;
    }

    button {
      width: auto;
      display: flex;
      align-items: center;
      border: none;
      border-radius: 12px;
      background: #e5e5ea;
      padding: 10px 16px;
      color: #636366;
      font-weight: 600;
      font-size: 12px !important;
      cursor: pointer;
      svg {
        margin-inline-end: 5px;
        width: 14px;
      }
    }
  }
  .groupsSkeleton {
    padding-inline: 12%;
    padding-block: 3vh;
    opacity: 0.5;
    & > div {
      padding-block: 5px;
      ul {
        display: none;
      }
      h3 {
        width: 100% !important;
      }
    }
  }
  @include xl {
  }
  .chatRooms {
    // height: 77vh;
    overflow-y: auto;
    padding-inline: 8%;
    &::-webkit-scrollbar {
      width: 0.3em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eee;
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-block: 13px;
      padding-inline: 14px;
      border-bottom: 1px solid #eff1f3;
      margin-block: 4px;
      cursor: pointer;
      &.active {
        background-color: #eff1f3;
        border-bottom: 1px solid transparent !important;
        border-radius: 12px;
      }
      img {
        width: 40px;
        height: 40px;
        background-color: #eee;
        border-radius: 50%;
        object-fit: contain;
        margin-inline-end: 15px;
        flex-shrink: 0;
        flex-grow: 0;
      }
      .infoCon {
        flex-grow: 1;
        max-width: 72%;
        .titleCon {
          display: flex;
          text-transform: capitalize;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 3px;
          h4 {
            color: #0d0f11;
            font-weight: 600 !important;
          }
          .time {
            color: #5d5f618f;
            font-size: 11px !important;
            font-weight: 500;
          }
          button {
            border: none !important;
            span {
              font-weight: 600;
            }
          }
        }
        .desCon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h5 {
            color: #0d0f11;
            font-weight: 600 !important;
            flex-grow: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .counter {
            color: #393e46;
            font-size: 10px !important;
            background-color: #ff453a;
            color: white;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            flex-grow: 0;
            flex-shrink: 0;
            font-weight: 500;
            margin-inline-start: 3px;
          }
        }
      }
    }
  }
}
.messages {
  height: 100%;
  background-color: $mid-light-bg;
  box-sizing: border-box;
  .emptyMessages {
    padding: 30vh 10% 5vh 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.7;
    pointer-events: none;
    svg {
      width: 60px;
    }
    h3 {
      color: #1c1c1e;
      font-size: 16px !important;
    }
    p {
      color: #636366;
      font-size: 12px !important;
    }
  }
  .chatHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f8f9;
    padding: 2.5vh 3%;
    & > div:first-child {
      display: flex;
      align-items: center;
      .backBtn {
        width: fit-content;
        padding: 0;
        border: none;
        background-color: transparent;
        width: 18px;
        height: 18px;
        margin-inline-end: 15px;
        cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
        }
      }
      .groupInfo {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: contain;
          background-color: #eee;
          margin-inline-end: 12px;
        }
        h3 {
          font-weight: 600 !important;
        }
      }
    }
    .groupBtns {
      button {
        width: fit-content;
        padding: 0;
        border: none;
        background-color: transparent;
        margin-inline-start: 20px;
        cursor: pointer;
        width: 21px;
        height: 21px;
        svg {
          width: 21px;
          height: 21px;
        }
      }
    }
  }

  .roomBox {
    // background-color: white;
    height: 76.5vh;
    position: relative;
    .messagesSkeleton {
      overflow: hidden;
      padding: 0 4%;
      height: 100%;
      padding-top: 3vh;
      padding-bottom: 10vh;
      opacity: 0.3;
      h3 {
        width: 30%;
        height: 50px;
        border-radius: 8px;
      }
      ul {
        li {
          width: 30%;
          height: 50px;
          border-radius: 8px;
          &:nth-child(1) {
            margin-left: auto;
          }
          &:nth-child(3) {
            width: 60%;
          }
          &:nth-child(4) {
            margin-left: auto;
          }
          &:nth-child(5) {
            width: 60%;
            margin-left: auto;
          }
        }
      }
    }

    .chatBg {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 1;
      left: 0;
      top: 0;
      mix-blend-mode: overlay;
      object-fit: cover;
      pointer-events: none;
    }
    .sendMessageBox {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #f7f8f9;
      border-top: 1px solid #e5e5ea;
      width: 100%;
      padding: 2vh 4%;
      box-sizing: border-box;
      display: flex;
      z-index: 6;
      .attachmentBtn {
        width: fit-content;
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;
        margin-inline-end: 5px;
        cursor: pointer;
        svg {
          width: 22px;
          height: 22px;
        }
      }
      .recordBtn {
        width: fit-content;
        border: none;
        background-color: #f2f2f7;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;
        margin-inline-start: 10px;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .sendBtn {
        width: fit-content;
        border: none;
        background: linear-gradient(223.01deg, #bf4034 -5.79%, #9b1724 109.35%);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;
        margin-inline-start: 10px;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      input {
        border: 1px solid #e7eaee !important;
        background-color: white;
        border-radius: 42px;
        flex-grow: 1;
        padding: 10px 25px;
        font-weight: 600;
        color: black;
        box-sizing: border-box;
        &::placeholder {
          color: #8e8e93;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .sendMessageNotAlowed {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #e5e5ea;
      border-top: 1px solid #d1d1d6;
      width: 100%;
      padding: 3vh 4%;
      box-sizing: border-box;
      display: flex;
      z-index: 6;
      color: #48484a;
      align-items: center;
      justify-content: center;
    }
    .roomChat {
      padding: 0 4%;
      height: 100%;
      padding-top: 10vh;
      padding-bottom: 10vh;
      box-sizing: border-box;
      z-index: 5;
      position: relative;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.4em;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 30px;
      }
      & > div {
        display: flex;
        margin-bottom: 2vh;
        max-width: 70%;

        .messageMenu {
          box-shadow: 0px 4px 22px 0px #00000040;
          display: flex;
          flex-direction: column;
          border: 1px solid #f2f2f7;
          background: white;
          border-radius: 12px;
          width: 140px;
          height: auto;
          position: absolute;
          top: 0%;
          inset-inline-end: 0;
          transform: translate(80%, -70%);
          z-index: 20;
          padding: 5px;
          button {
            border: none !important;
            background-color: transparent !important;
            font-weight: 600 !important;
            font-size: 12px !important;
            padding: 6px 10px;
            text-align: start;
            display: flex;
            align-items: center;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
              background-color: #eee !important;
            }
            img {
              height: 18px;
              width: 18px;
              object-fit: contain;
              margin-inline-end: 8px;
            }
          }
        }
        .userIcon {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
          margin-inline-end: 15px;
          background-color: white;
        }
        .messageBox {
          border-radius: 0px 12px 12px 12px;
          background-color: white;
          padding: 1.3vh 14px 6vh;
          position: relative;
          min-width: 45%;
          position: relative;
          audio {
            background-color: transparent !important;
            border: none !important;
            margin-top: 8px !important;
          }
          audio::-webkit-media-controls-panel {
            background-color: transparent !important;
            border: none !important;
            margin: 0 !important;
            border-radius: 10px;
          }
          audio::-webkit-media-controls-enclosure {
            background-color: transparent !important;
            border: none !important;
            margin: 0 !important;
            border-radius: 10px;
          }

          video {
            width: fit-content;
            margin: 0 auto;
            height: auto;
            max-height: 50vh;
            margin-top: 8px;
            border-radius: 10px;
            display: block;
          }
          .fileCard {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #f2f2f7;
            padding: 15px 14px;
            margin-bottom: 5px;
            margin-top: 3px;
            border-radius: 6px;
            svg {
              width: 35px;
              height: 35px;
            }
            .fileIcon {
              border-radius: 0 !important;
              width: 28px;
              height: 28px;
              object-fit: contain;
              margin-inline-end: 10px;
              object-fit: contain;
            }
            .fileName {
              color: #48484a;
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 3px;
            }
            .fileDes {
              color: #636366;
              font-weight: 400;
              font-size: 12px;
            }
            .downFileIcon {
              margin-inline-start: 50px;
              width: 22px;
              height: 22px;
              object-fit: contain;
            }
          }
          :global(.ant-image) {
            width: 100%;

            padding-block: 10px !important;
            :global(.ant-image-mask) {
              display: none !important;
            }
            :global(.messageImg) {
              width: 100% !important;
              height: 100% !important;
              border-radius: 5px !important;
              margin: 0 !important;
              min-height: 15vh;
              max-height: 40vh;
              object-fit: contain;
            }
          }
          .replyBox {
            background-color: #f2f2f7;
            padding: 7px 10px;
            margin-bottom: 5px;
            margin-top: 3px;
            border-radius: 6px;
            h5 {
              color: #0d0f11aa !important;
              font-size: 12px !important;
            }
            .repliedMessage {
              color: #0d0f11;
              font-size: 11px !important;
            }
          }
          h3 {
            color: #70ace0;
            font-weight: 600;
            font-size: 14px !important;
          }
          p {
            color: #0d0f11;
            line-height: 10px;
            font-size: 13px !important;
            font-weight: 400 !important;
            line-height: 24px;
          }
          .time {
            color: #0d0f11;
            font-size: 10px;
            font-weight: 500;
            position: absolute;
            bottom: 6px;
            inset-inline-end: 10px;
            display: flex;
            align-items: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            .sendedIconCon {
              font-size: 10px;
              margin-inline-start: 5px;
              width: 17px;
              height: 17px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 17px;
                height: 17px;
                object-fit: contain;
              }
              svg {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
        &.mine {
          margin-inline-start: auto;
          display: flex;
          justify-content: end;
          .messageBox {
            background: linear-gradient(
              223.01deg,
              #bf4034 -5.79%,
              #9b1724 109.35%
            );
            border-radius: 12px 0px 12px 12px;
            .fileCard {
              background: #00000029;
              .fileName {
                color: white !important;
              }
              .fileDes {
                color: white !important;
              }
              .downFileIcon {
                filter: brightness(0) invert(1);
              }
            }
            audio {
              opacity: 1;
            }
            audio::-webkit-media-controls-play-button,
            audio::-webkit-media-controls-mute-button,
            audio::-webkit-media-controls-timeline,
            audio::-webkit-media-controls-volume-slider,
            audio::-webkit-media-controls-toggle-closed-captions-button,
            audio::-webkit-media-controls-rewind-button,
            audio::-webkit-media-controls-return-to-realtime-button,
            audio::-webkit-media-controls-toggle-closed-captions-button,
            audio::-webkit-media-controls-panel {
              filter: brightness(0) invert(1);
            }
            audio::-webkit-media-controls-current-time-display,
            audio::-webkit-media-controls-time-remaining-display {
              color: white;
            }
            .messageMenu {
              inset-inline-end: auto;
              inset-inline-start: 0;
              transform: translate(-90%, -80%);
            }
            .replyBox {
              background: #00000029;
              h5 {
                color: white !important;
              }
              .repliedMessage {
                color: white;
              }
            }
            p {
              color: white;
            }
            .time {
              color: white;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  :global(.ant-tabs-tab + .ant-tabs-tab) {
    margin: 0 0 0 10px;
  }
  :global(.ant-tabs-nav::before) {
    content: initial;
  }
  :global(.ant-tabs-nav) {
    margin-bottom: 10px;
    position: absolute;
    top: 14vh;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e9e9e9;
    padding: 2px;
    border-radius: 8px;
    border: none !important;

    :global(.ant-tabs-nav-list) {
      margin: 0 auto;
    }

    :global(.ant-tabs-ink-bar) {
      display: none !important;
    }
    :global(.ant-tabs-tab) {
      padding: 0 !important;
      :global(.ant-tabs-tab-btn) {
        color: #555d68;
        padding: 7px 40px;
        border-radius: 7px;
        font-weight: 500;
        font-size: 12px;
      }
    }
    :global(.ant-tabs-tab-active > div) {
      color: #0d0f11 !important;
      background-color: #f7f8f9 !important;
      border-radius: 7px;
    }
  }
  @include xl {
  }
}
:global(.filesDropDown) {
  :global(.ant-dropdown-menu) {
    display: flex;
    background-color: white;
    :global(.ant-dropdown-menu-item) {
      padding-inline: 5px;
      button,
      label {
        background-color: transparent;
        border: none;
        font-family: "Bold";
        color: #333;
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 70%;
          height: 70%;
          object-fit: contain;
        }
      }
      input {
        display: none;
      }
    }
  }
}

/*****************
 * STARTING HERE *
 *****************/

.user__tabs {
  background-color: white;
  > div:first-child {
    margin-left: 24px;
  }

  div[role="tab"] {
    //styleName: Paragraph/02/SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.86px;
    text-align: left;
    color: #636366;
  }
}
:global {
  .ant-tabs-tab-active * {
    color: #1c1c1e !important;
  }
  .ant-tabs-ink-bar {
    background-color: #1c1c1e !important;
    height: 3px !important;
    border-radius: 0 !important;
  }
}

.edit__user__btn {
  width: 95px;
  height: 40px;
  padding: 10px 20px 10px 20px;
  gap: 8px;
  border-radius: 10px;
  border: 0px 0px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f7;
  span {
    font-weight: 600;
  }
  svg {
    width: 13px;
    height: 13px;
  }
}

/*********
 * CALLS *
 *********/

.segmented__container {
  background-color: white;
  display: flex;
  justify-content: center;

  label {
    width: 178px;
    height: 36px;
    padding: 10px 8px 10px 8px;
    gap: 0px;
    border-radius: 6px 0px 0px 0px;
    opacity: 0px;
  }
}

:global(
    .react-international-phone-country-selector-dropdown__list-item-flag-emoji
  ) {
  width: 20px !important;
}
:global(#horizontal_login_phone) {
  width: 100% !important;
}
.chnagePasswordModal {
  .ant-modal-confirm-title {
    padding-block: 10px;
  }
  .ant-modal-content {
    border-radius: 32px;
    .ant-upload {
      width: 100% !important;
      .uploadbtncon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
      }
      .placeHolderImg {
        width: 65px;
        height: 65px;
        object-fit: cover;
        border-radius: 50%;
        margin-inline-end: 10px;
      }
      .uploadtext {
        flex-grow: 1;
        text-align: start;
      }
      .uploadbtn {
        color: #9b1724;
        display: flex;
        justify-content: center;
      }
    }
    .ant-select-outlined {
      background-color: #f7f8f9;
      border-radius: 15px;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 3px,
        rgba(0, 0, 0, 0.17) 0px 1px 2px;
      padding: 6px;
      .ant-select-selector {
        border: none;
        background: transparent;
        &:active {
          border: none;
        }
      }
    }
  }
  .editinglog__steps {
    .ant-steps-item-container {
      .ant-steps-item-tail {
        inset-inline-start: 1px !important;
        padding: 0 !important;
        &::after {
          width: 4px !important;
          margin-inline-start: 4.5px !important;
        }
      }
    }

    .ant-steps-item {
      &:hover * {
        color: unset !important;
      }
      &:hover p,
      &:hover .editedby__log {
        color: white !important;
      }
      &:hover div {
        color: #636366 !important;
      }
    }

    .ant-steps-item-icon {
      width: 15px !important;
      height: 15px !important;
      gap: 0 !important;
      border-radius: 50% !important;
      inset-inline-start: 0 !important;
      position: relative !important;
      border: 3px #f2f2f7 solid !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      background-color: #d1d1d6 !important;
    }
    .date__log {
      color: #636366 !important;
      font-size: 13px !important;
      text-transform: capitalize;
      &:hover {
        color: #636366 !important;
      }
    }
    .messagebox__log {
      background: linear-gradient(223.01deg, #bf4034 -5.79%, #9b1724 109.35%);
      border-radius: 12px 0px 12px 12px;
      padding: 1.3vh 14px 6vh;
      position: relative;
      min-width: 45%;
      width: fit-content;
      position: relative;
      text-align: start;
      p {
        color: white;
        line-height: 10px;
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 24px;
      }
      .editedby__log {
        color: white;
        font-size: 10px;
        font-weight: 500;
        position: absolute;
        bottom: 6px;
        inset-inline-start: 10px;
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  input {
    &:disabled {
      border-radius: 15px;
      color: unset;
      border-color: unset;
      box-shadow: unset;
      cursor: not-allowed;
      opacity: 1;
      background-color: #ffffff;
    }
  }
  :global(.ant-input-affix-wrapper) {
    border-radius: 10px !important;
    overflow: hidden;
  }
  :global(.ant-input-group-addon) {
    display: none;
  }
  input {
    margin-inline-start: 10px;
    font-weight: 500;
  }
  label {
    //styleName: Paragraph/02/SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.86px;
    text-align: left;
    color: #1c1c1e;
    margin-top: 55px;
    
  }
  .input__account {
  
    span {
      padding: 0;
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      border: none;
      background-color: $grey2;
      border-radius: 0 15px 15px 0;
      z-index: 1;
    }
    * {
      box-shadow: none;
    }

    margin: 10px 0;
    input {
      height: 56px;
      padding: 10px;
    }
  }
  :global(.ant-input-outlined) {
    background-color: #f7f8f9 !important;
    border-radius: 16px;
    border: none;
    padding-inline: 15px;
    padding-block: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px, rgba(0, 0, 0, 0.1) 0px 1px 2px;
  }
  .searchMessageCon {
    padding: 20px 0;
    .notFound {
      text-align: center;
      padding: 7px 20px;
      border-radius: 12px;
      margin-top: 10px;
      width: fit-content;
      margin: 14vh auto;
    }
    .searchMessage {
      position: relative;
      display: flex;

      .searchUser {
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
          margin-inline-end: 15px;
          background-color: white;
        }
      }

      .searchMessageText {
        width: 100%;
        p {
          background: linear-gradient(
            223.01deg,
            #bf4034 -5.79%,
            #9b1724 109.35%
          );
          color: white;
          border-radius: 0px 12px 12px 12px;
          margin-bottom: 20px;
          padding: 20px 20px 5px;
        }
      }
      .messageDate {
        text-align: end;
        font-size: 12px;
      }
    }
  }
}