@use "../base/_colors.scss" as *;

.forward{
    .chatRooms {
        height: 65.5vh;
        overflow-y: auto;
        padding-inline: 8%;
        &::-webkit-scrollbar {
          width: 0.3em;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #eee;
        }
        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-block: 13px;
          padding-inline: 14px;
          border-bottom: 1px solid #eff1f3;
          margin-block: 4px;
          cursor: pointer;
          &.active {
            background-color: #eff1f3;
            border-bottom: 1px solid transparent !important;
            border-radius: 12px;
          }
          img {
            width: 40px;
            height: 40px;
            background-color: #eee;
            border-radius: 50%;
            object-fit: contain;
            margin-inline-end: 15px;
            flex-shrink: 0;
            flex-grow: 0;
          }
          .infoCon {
            flex-grow: 1;
            max-width: 72%;
            .titleCon {
              display: flex;
              text-transform: capitalize;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 3px;
              h4 {
                color: #0d0f11;
                font-weight: 600 !important;
              }
              .time {
                color: #5d5f618f;
                font-size: 11px !important;
                font-weight: 500;
              }
            }
            .desCon {
              display: flex;
              justify-content: space-between;
              align-items: center;
              h5 {
                color: #0d0f11;
                font-weight: 600 !important;
                flex-grow: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .counter {
                color: #393e46;
                font-size: 10px !important;
                background-color: #ff453a;
                color: white;
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                flex-grow: 0;
                flex-shrink: 0;
                font-weight: 500;
                margin-inline-start: 3px;
              }
            }
          }
          button {
            width: fit-content;
            background-color: transparent;
            border: none !important;
            padding: 0;
            width: 18px !important;
            height: 18px !important;
            margin-inline-start: 5px;
            flex-shrink: 0;
            flex-grow: 0;
            transform: translateX(5px);
            cursor: pointer;
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
}