@use "../base/_colors.scss" as *;
@use "../base/_typography.scss" as *;
@use "../components/_sliders.scss" as *;
@use "../base/media-query" as *; // Import the responsive SCSS file

.spaces {
  // height: 100%;
  height: calc(100vh - 75px);
  border: 1px solid $light-bg;
  padding: 22px 0 0 8%;
  box-sizing: border-box;
  position: relative;
  background-color: white;

  .spacesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline-end: 10%;
    margin-bottom: 3vh;

    .title {
      color: $text-color;
      font-size: 18px;
    }

    .sortbyBtn {
      width: fit-content;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      font-size: 14px !important;
      font-weight: 600;
      cursor: pointer;

      svg {
        margin-inline-end: 5px;
      }
    }
  }

  .spacesBtns {
    min-height: 54vh;
    max-width: calc(91vh - 260px);
    overflow-y: auto;
    // padding-bottom: 20vh;
    text-transform: uppercase;

    &::-webkit-scrollbar {
      width: 0.3em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eee;
    }

    button {
      border-radius: 12px 0px 0px 12px;
      padding: 10px;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      width: 100% !important;
      cursor: pointer;

      svg,
      img {
        margin-inline-end: 10px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }

      &>div {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        text-align: start;
        margin-inline-end: 4px;
      }

      .name {
        text-transform: uppercase;
      }

      .counter {
        background-color: #f7f8f9;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 50%;
        font-weight: 600;
        margin-inline-end: 2%;
      }

      &.active {
        background-color: $light-bg;

        .counter {
          background-color: white;
        }
      }
    }
  }

  .spacesManagment {
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    padding: 1vh 10px 2vh 10%;
    background-color: white;
    width: 100%;

    button {
      border-radius: 12px 0px 0px 12px;
      padding: 8px 10px;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
      cursor: pointer;

      svg,
      img {
        margin-inline-end: 10px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }

      &>div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
      }

      .counter {
        background-color: #f7f8f9;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 50%;
        font-weight: 600;
        margin-inline-end: 5%;
      }

      &.active {
        background-color: $light-bg;
      }
    }
  }

  @include xl {}
}

.mediaPreview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  z-index: 10;
  padding: 50px 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .closeBtn {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 20px;
    height: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .mainImg {
    height: 35vh;
    min-width: 60%;
    width: fit-content;
    object-fit: contain;
    margin: 0 auto 10px;
  }

  .imagesCon {
    display: flex;

    .smallImg {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      margin-inline-end: 10px;
      border: 1px solid transparent;
      cursor: pointer;
      border-radius: 5px;
      object-fit: contain;

      &.active {
        border: 1px solid #9b1724;
      }
    }
  }

  .inputCon {
    display: flex;
    align-items: center;
    gap: 20px;

    input {
      background-color: transparent !important;
      border-bottom: 1px solid #9b1724;
      border-radius: 0 !important;
      font-weight: 500;
      color: #0d0f11;
      padding-block: 10px;

      &::placeholder {
        color: #888 !important;
        font-weight: 500;
      }
    }

    .sendBtn {
      width: fit-content;
      border: none;
      background: linear-gradient(223.01deg, #bf4034 -5.79%, #9b1724 109.35%);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 0;
      margin-inline-start: 10px;
      cursor: pointer;
      flex-shrink: 0;

      &.disabled {
        pointer-events: none !important;
        opacity: 0.8;
        cursor: initial;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.groups {
  height: 100%;
  background-color: $light-bg;
  border-inline-end: 1px solid #e7eaee;
  padding-top: 25px;
  padding-bottom: 2vh;
  box-sizing: border-box;

  .groupsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vh;
    padding-inline: 8%;

    .title {
      color: #8e8e93;
      font-weight: 600;
      font-size: 12px;
      // text-transform: uppercase;
    }

    button {
      border: none;
      background-color: transparent;
      width: fit-content;
      font-size: 12px !important;
      color: #555d68;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.red {
        color: #9b1724;
      }

      svg {
        margin-inline-end: 2px;
      }
    }
  }

  .emptypage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;

    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
      margin-bottom: 10px;
      filter: grayscale(100%);
    }

    h4 {
      margin-bottom: 6px;
      font-size: 15px !important;
    }

    p {
      font-size: 12px !important;
      color: #393e46;
      margin-bottom: 15px;
    }

    button {
      width: auto;
      display: flex;
      align-items: center;
      border: none;
      border-radius: 12px;
      background: #e5e5ea;
      padding: 10px 16px;
      color: #636366;
      font-weight: 600;
      font-size: 12px !important;
      cursor: pointer;

      svg {
        margin-inline-end: 5px;
        width: 14px;
      }
    }
  }

  .groupsSkeleton {
    padding-inline: 12%;
    padding-block: 3vh;
    opacity: 0.5;

    &>div {
      padding-block: 5px;

      ul {
        display: none;
      }

      h3 {
        width: 100% !important;
      }
    }
  }

  .searchCon {
    padding-inline: 8% !important;
    margin-bottom: 1vh;

    :global(.ant-input-affix-wrapper) {
      padding-inline: 10px;
    }

    * {
      border: none !important;
      box-shadow: none !important;
    }

    :global(.ant-input-affix-wrapper) {
      border-radius: 16px !important;
      overflow: hidden;
    }

    :global(.ant-input-group-addon) {
      display: none;
    }

    *>span {
      background: #f2f2f7 !important;
      border-bottom: 1px solid #e7eaee !important;
      border-radius: 16px !important;
      font-weight: 500;
      border: none !important;
      padding: 5px 0px;

      &:last-child {
        padding: 5px 8px;
      }

      input {
        font-weight: 500;
        border: none !important;
        box-shadow: none !important;
        padding-inline-start: 15px;
      }

      input::placeholder {
        background: #f2f2f7 !important;
        color: #8e8e93;
        border: none !important;
        font-weight: 500 !important;
      }
    }
  }

  @include xl {}
}

.chatRooms {
  height: 65.5vh;
  overflow-y: auto;
  padding-inline: 8%;
  flex-grow: 1;

  @media only screen and (min-width: 1600px) {
    height: 81vh;
  }

  &::-webkit-scrollbar {
    width: 0.3em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee;
  }

  .chatItem {
    display: flex;

    .dropdown {
      display: flex;
      align-items: center;
      width: 100%;

      &.active {
        background-color: #eff1f3;
        border-bottom: 1px solid transparent !important;
        border-radius: 12px;
      }

      :global(.ant-menu) {
        background: transparent;

        :global(.ant-menu-submenu-title) {
          padding: 0;
          margin: 0;
          width: auto;
          height: auto;
        }
      }
    }

    &>div>div {
      display: flex;
      align-items: center;
      padding-block: 13px;
      padding-inline: 14px;
      border-bottom: 1px solid #eff1f3;
      margin-block: 4px;
      flex-grow: 1;
      width: -webkit-fill-available;
      // max-width: 90%;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        background-color: #eee;
        border-radius: 50%;
        object-fit: contain;
        margin-inline-end: 15px;
        flex-shrink: 0;
        flex-grow: 0;
      }

      .favCon {
        position: relative;

        .fav {
          position: absolute;
          bottom: -5px;
          right: 10px;
        }
      }

      .infoCon {
        flex-grow: 1;

        // max-width: 84%;
        .titleCon {
          display: flex;
          text-transform: uppercase;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 3px;

          h4 {
            color: #0d0f11;
            font-weight: 600 !important;
            text-transform: capitalize;
          }
 
    

          .time {
            color: #5d5f618f;
            font-size: 11px !important;
            font-weight: 500;
          }
        }

        .desCon {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h5 {
            color: #0d0f11;
            font-weight: 600 !important;
            flex-grow: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .counter {
            color: #393e46;
            font-size: 10px !important;
            background-color: #ff453a;
            color: white;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            flex-grow: 0;
            flex-shrink: 0;
            font-weight: 500;
            margin-inline-start: 3px;
          }
        }
      }

      button {
        width: fit-content;
        background-color: transparent;
        border: none !important;
        padding: 0;
        width: 18px !important;
        height: 18px !important;
        margin-inline-start: 10px;
        flex-shrink: 0;
        flex-grow: 0;
        transform: translateX(5px);
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.optionMenu {
  :global(.ant-menu-sub) {
    background-color: #bf4034;

    :global(.ant-menu-item),
    :global(.ant-menu-submenu .ant-menu-submenu-title) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline-end: 16px;

      :global(.ant-menu-title-content) {
        flex-grow: 1;
      }
    }
  }
}

.messages {
  height: 100%;
  background-color: $mid-light-bg;
  box-sizing: border-box;

  .emptyMessages {
    padding: 30vh 10% 5vh 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.7;
    pointer-events: none;

    svg {
      width: 60px;
    }

    h3 {
      color: #1c1c1e;
      font-size: 16px !important;
    }

    p {
      color: #636366;
      font-size: 12px !important;
    }
  }

  .chatHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f8f9;
    padding: 16px 3%;

    &>div:first-child {
      display: flex;
      align-items: center;

      .backBtn {
        width: fit-content;
        padding: 0;
        border: none;
        background-color: transparent;
        width: 18px;
        height: 18px;
        margin-inline-end: 15px;
        cursor: pointer;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .groupInfo {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: contain;
          background-color: #eee;
          margin-inline-end: 12px;
        }

        h3 {
          font-weight: 600 !important;
        }

        p {
          color: #555;
          font-size: 12px !important;
          margin-bottom: 0 !important;
          margin-top: 3px;

          span {
            color: darkgreen;
          }
        }
      }
    }

    .groupBtns {
      button {
        width: fit-content;
        padding: 0;
        border: none;
        background-color: transparent;
        margin-inline-start: 20px;
        cursor: pointer;
        width: 21px;
        height: 21px;

        svg {
          width: 21px;
          height: 21px;
        }
      }
    }
  }

  .roomBox {
    // background-color: white;
    // height: 76.5vh;
    position: relative;

    .messagesSkeleton {
      overflow: hidden;
      padding: 0 25px;
      height: 100%;
      padding-top: 3vh;
      padding-bottom: 10vh;
      opacity: 0.3;

      h3 {
        width: 30%;
        height: 50px;
        border-radius: 8px;
      }

      ul {
        li {
          width: 30%;
          height: 50px;
          border-radius: 8px;

          &:nth-child(1) {
            margin-left: auto;
          }

          &:nth-child(3) {
            width: 60%;
          }

          &:nth-child(4) {
            margin-left: auto;
          }

          &:nth-child(5) {
            width: 60%;
            margin-left: auto;
          }
        }
      }
    }

    .chatBg {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 1;
      left: 0;
      top: 0;
      mix-blend-mode: overlay;
      object-fit: cover;
      pointer-events: none;
    }

    .sendMessageBox {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #f7f8f9;
      border-top: 1px solid #e5e5ea;
      width: 100%;
      padding: 2vh 4%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      z-index: 6;
      justify-content: end;

      .attachmentBtn {
        width: fit-content;
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;
        margin-inline-end: 5px;
        cursor: pointer;

        svg {
          width: 22px;
          height: 22px;
        }
      }

      .recordCon {
        display: flex;
        align-items: center;

        &>button {
          border: none !important;
          background-color: transparent;
          margin-inline-end: 15px;

          img {
            height: 20px;
            width: 20px;
            object-fit: contain;
          }
        }

        :global(.voice-visualizer__btn) {
          display: none;
        }

        :global(.voice-visualizer) {
          display: flex;
          border: 1px solid #e7eaee;
          border-radius: 42px;
          justify-content: space-between;
          padding-inline: 10px;
          padding-block: 3px;
          align-items: center;
          min-width: 40%;
        }

        :global(.voice-visualizer__btn-container) {
          min-width: initial !important;
        }

        :global(.voice-visualizer__audio-info-container) {
          height: fit-content !important;
        }

        :global(.voice-visualizer__canvas-container) {
          width: 150px !important;

          * {
            font-size: 14px !important;
            font-weight: 600 !important;
          }
        }

        :global(.voice-visualizer__canvas-audio-processing) {
          font-size: 14px !important;
          max-width: initial !important;
          width: auto !important;
        }

        :global(.voice-visualizer__audio-info-time),
        :global(.voice-visualizer__audio-info-container p) {
          color: #636366;
          font-weight: 600;
          font-size: 12px !important;
          margin: 0 !important;
          margin-inline-start: 15px !important;
        }

        :global(.voice-visualizer__progress-indicator),
        :global(.voice-visualizer__progress-indicator-hovered) {
          display: none !important;
        }

        :global(.voice-visualizer__buttons-container) {
          margin: 0 !important;
          order: -1;
          column-gap: 0;
          row-gap: 0;
          margin-inline-end: 13px !important;

          button {
            margin: 0 !important;
            border: none !important;
            box-shadow: none !important;
            background-color: transparent !important;
            width: 30px;
            height: 30px;

            img {
              filter: brightness(20%);
              width: 12px !important;
              height: 12px !important;
              object-fit: contain;
            }
          }
        }
      }

      .recordBtn {
        width: fit-content;
        border: none;
        background-color: #f2f2f7;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;
        margin-inline-start: 10px;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .sendBtn {
        width: fit-content;
        border: none;
        background: linear-gradient(223.01deg, #bf4034 -5.79%, #9b1724 109.35%);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;
        margin-inline-start: 10px;
        cursor: pointer;

        &.disabled {
          pointer-events: none !important;
          opacity: 0.8;
          cursor: initial;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .inputCon {
        border: 1px solid #e7eaee !important;
        background-color: white;

        padding: 10px 15px 5px;
        flex-grow: 1;

        .replayBox {
          background: #f2f2f7;
          border-radius: 12px;
          padding: 10px 18px;
          margin-bottom: 10px;
          position: relative;

          button {
            position: absolute;
            inset-inline-end: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
            padding: 0;
            background-color: transparent;
            border: none !important;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          h4 {
            color: #d680ff;
            font-weight: 600;
            font-size: 14px !important;
            margin: 0 !important;
            margin-bottom: 2px !important;
          }

          p {
            color: #636366;
            font-size: 13px !important;
            margin: 0 !important;
          }
        }

        textarea {
          width: 100%;
          font-weight: 600;
          color: black;
          box-sizing: border-box;
          background-color: transparent !important;
          border: none !important;
          padding: 3px 10px;
          resize: none;

          &::-webkit-scrollbar {
            width: 0.3em;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #eee;
          }

          &::placeholder {
            color: #8e8e93;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .sendMessageNotAlowed {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #e5e5ea;
      border-top: 1px solid #d1d1d6;
      width: 100%;
      padding: 3vh 4%;
      box-sizing: border-box;
      display: flex;
      z-index: 6;
      color: #48484a;
      align-items: center;
      justify-content: center;
    }

    .roomChat {
      padding: 0 25px;
      height: 100%;
      padding-top: 10vh;
      padding-bottom: 10vh;
      box-sizing: border-box;
      z-index: 5;
      position: relative;
      overflow-y: auto;

      // scroll-behavior: smooth;
      &::-webkit-scrollbar {
        width: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 30px;
      }

      &>div {
        display: flex;
        margin-bottom: 2vh;
        max-width: 70%;

        .userIcon {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
          margin-inline-end: 15px;
          background-color: white;
        }

        .messageBox {
          border-radius: 0px 12px 12px 12px;
          background-color: white;
          padding: 1.3vh 14px 0vh;
          position: relative;
          min-width: 45%;
          position: relative;

          p {
            word-break: break-word;
          }

          .forwarded {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 12px;

            svg {
              height: 17px;
              width: 17px;
              margin-inline-end: 5px;
            }
          }

          audio {
            background-color: transparent !important;
            border: none !important;
            margin-top: 8px !important;
          }

          audio::-webkit-media-controls-panel {
            background-color: transparent !important;
            border: none !important;
            margin: 0 !important;
            border-radius: 10px;
          }

          audio::-webkit-media-controls-enclosure {
            background-color: transparent !important;
            border: none !important;
            margin: 0 !important;
            border-radius: 10px;
          }

          video {
            width: fit-content;
            margin: 0 auto;
            height: auto;
            max-height: 50vh;
            margin-top: 8px;
            border-radius: 10px;
            display: block;
          }

          .fileCard {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #f2f2f7;
            padding: 15px 14px;
            margin-bottom: 5px;
            margin-top: 3px;
            border-radius: 6px;

            svg {
              width: 35px;
              height: 35px;
            }

            .fileIcon {
              border-radius: 0 !important;
              width: 28px;
              height: 28px;
              object-fit: contain;
              margin-inline-end: 10px;
              object-fit: contain;
            }

            .fileName {
              color: #48484a;
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 3px;
            }

            .fileDes {
              color: #636366;
              font-weight: 400;
              font-size: 12px;
            }

            .downFileIcon {
              width: 22px;
              height: 22px;
              object-fit: contain;
            }
          }

          :global(.ant-image) {
            width: 100%;

            padding-block: 10px !important;

            :global(.ant-image-mask) {
              display: none !important;
            }

            :global(.messageImg) {
              width: 100% !important;
              height: 100% !important;
              border-radius: 5px !important;
              margin: 0 !important;
              min-height: 15vh;
              max-height: 40vh;
              object-fit: contain;
            }
          }

          .replyBox {
            background-color: #f2f2f7;
            padding: 7px 10px;
            margin-bottom: 5px;
            margin-top: 3px;
            border-radius: 6px;

            h5 {
              color: #0d0f11aa !important;
              font-size: 12px !important;
            }

            .repliedMessage {
              color: #0d0f11;
              font-size: 11px !important;
            }
          }

          h3 {
            color: #70ace0;
            font-weight: 600;
            font-size: 14px !important;
          }

          p {
            color: #0d0f11;
            line-height: 10px;
            font-size: 13px !important;
            font-weight: 400 !important;
            line-height: 24px;
          }

          .time {
            color: #0d0f11;
            font-size: 10px;
            font-weight: 500;
            // position: absolute;
            // bottom: 6px;
            padding-block: 4vh 6px;
            inset-inline-end: 10px;
            display: flex;
            align-items: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            .sendedIconCon {
              font-size: 10px;
              margin-inline-start: 5px;
              width: 17px;
              height: 17px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 17px;
                height: 17px;
                object-fit: contain;
              }

              svg {
                width: 13px;
                height: 13px;
              }
            }
          }
        }

        &:has(.messageReactions) {
          .messageBox .time {
            padding-block: 0px 6px;
          }
        }

        .messageReactions {
          display: flex;
          align-items: center;
          margin-block: 4vh 2px;
          justify-content: end;

          .messageReaction {
            background-color: rgba($color: #fff, $alpha: 0.1);
            border-radius: 5px;
            padding: 6px 8px 2px;
            margin-inline-end: 5px;
            cursor: pointer;

            img {
              width: 18px;
              height: 18px;
              object-fit: contain;
              border-radius: 50%;
            }
          }
        }

        &.mine {
          margin-inline-start: auto;
          display: flex;
          justify-content: end;

          .messageBox {
            background: linear-gradient(223.01deg,
                #bf4034 -5.79%,
                #9b1724 109.35%);
            border-radius: 12px 0px 12px 12px;

            .messageReactions {
              justify-content: start;
            }

            .forwarded {
              color: white;
              font-weight: 400;

              svg * {
                stroke: white;
              }
            }

            .fileCard {
              background: #00000029;

              .fileName {
                color: white !important;
              }

              .fileDes {
                color: white !important;
              }

              .downFileIcon {
                filter: brightness(0) invert(1);
              }
            }

            audio {
              opacity: 1;
            }

            audio::-webkit-media-controls-play-button,
            audio::-webkit-media-controls-mute-button,
            audio::-webkit-media-controls-timeline,
            audio::-webkit-media-controls-volume-slider,
            audio::-webkit-media-controls-toggle-closed-captions-button,
            audio::-webkit-media-controls-rewind-button,
            audio::-webkit-media-controls-return-to-realtime-button,
            audio::-webkit-media-controls-toggle-closed-captions-button,
            audio::-webkit-media-controls-panel {
              filter: brightness(0) invert(1);
            }

            audio::-webkit-media-controls-current-time-display,
            audio::-webkit-media-controls-time-remaining-display {
              color: white;
            }

            .messageMenuCon {
              inset-inline-end: auto;
              inset-inline-start: 0;
              transform: translate(-90%, -40%);
              // .messageMenu {
              // }
            }

            .replyBox {
              background: #00000029;

              h5 {
                color: white !important;
              }

              .repliedMessage {
                color: white;
              }
            }

            p {
              color: white;
            }

            .time {
              color: white;
              font-weight: 400;
              justify-content: end;
            }
          }
        }
      }
    }
  }

  :global(.ant-tabs-tab + .ant-tabs-tab) {
    margin: 0 0 0 10px;
  }

  :global(.ant-tabs-nav::before) {
    content: initial;
  }

  :global(.ant-tabs-nav) {
    margin-bottom: 10px;
    position: absolute;
    top: 80px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e9e9e9;
    padding: 2px;
    border-radius: 8px;
    border: none !important;

    :global(.ant-tabs-nav-list) {
      margin: 0 auto;
    }

    :global(.ant-tabs-ink-bar) {
      display: none !important;
    }

    :global(.ant-tabs-tab) {
      padding: 0 !important;

      :global(.ant-tabs-tab-btn) {
        color: #555d68;
        padding: 7px 40px;
        border-radius: 7px;
        font-weight: 500;
        font-size: 12px;
      }
    }

    :global(.ant-tabs-tab-active > div) {
      color: #0d0f11 !important;
      background-color: #f7f8f9 !important;
      border-radius: 7px;
    }
  }

  @include xl {}
}

.messageMenuCon {
  .reactionsCon {
    transform: translate(0, 9px);
    border: 1px solid #f2f2f7;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.2509803922);
    background: white;
    padding: 2px 5px 2px;
    border-radius: 111px;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-inline-start: auto;

    .reaction {
      margin-inline: 10px;
      cursor: pointer;

      img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
    }
  }
}

:global(.filesDropDown) {
  :global(.ant-dropdown-menu) {
    display: flex;
    background-color: white;

    :global(.ant-dropdown-menu-item) {
      padding-inline: 5px;

      button,
      label {
        background-color: transparent;
        border: none;
        font-family: "Bold";
        color: #333;
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 70%;
          height: 70%;
          object-fit: contain;
        }
      }

      input {
        display: none;
      }
    }
  }
}

:global(.ant-popover) {
  top: 15px;

  :global(.ant-popover-inner) {
    background-color: white;
  }

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.reactionInfo) {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 50%;
    margin-inline-end: 15px;
  }
}

