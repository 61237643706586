// _responsive.scss

// Media query mixins based on Ant Design breakpoints

$breakpoints: (
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1600px,
);

@mixin sm {
  @media (max-width: map-get($breakpoints, "sm")) {
    @content;
  }
}
@mixin md {
  @media (max-width: map-get($breakpoints, "md")) {
    @content;
  }
}
@mixin lg {
  @media (max-width: map-get($breakpoints, "lg")) {
    @content;
  }
}
@mixin xl {
  @media (max-width: map-get($breakpoints, "xl")) {
    @content;
  }
}
@mixin xxl {
  @media (max-width: map-get($breakpoints, "xxl")) {
    @content;
  }
}

@mixin breakpoint($bp: 0) {
  @media (max-width: $bp) {
    @content;
  }
}
