$primary-color: linear-gradient(
  to bottom,
  #bf4034,
  #b63730,
  #ad2d2c,
  #a42328,
  #9b1724
);
$light-grey: #636366;
$grey2: #e7eaee;
$light-bg: #f7f8f9;
$mid-light-bg: #f1f2f4;
$text-color: #0d0f11;
