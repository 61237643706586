.members {
  .membersView {
    padding-top: 10px;
    .count {
      font-weight: bold;
    }
    .head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .manageBtn {
        color: #9b1724;
        background-color: #9b17241f;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        padding: 5px 10px;
        cursor: pointer;
        svg {
          width: 21px;
          height: 21px;
          margin-inline-end: 5px;
        }
      }
    }
    .memberItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-block: 4px;
      padding-block: 4px;
      width: 100%;
      border-bottom: 1px solid #eff1f3;
      .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-inline-end: 10px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .info {
        .name {
          font-weight: bold;
        }
        .role {
          font-size: 14px;
        }
      }
      .actions {
      }
    }
  }
  .tabs {
    margin: 10px;
    [class~="ant-tabs-nav"] {
      position: relative;
      margin: 0;
      top: 0;
      z-index: 0;
      left: 0;
      transform: translate(0);
      background-color: transparent;
      [class~="ant-tabs-tab"] {
        flex-grow: 1;
        [class~="ant-tabs-tab-btn"] {
          //   padding: 7px;
        }
      }
      [class~="ant-tabs-nav-list"] {
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        [class~="ant-tabs-tab"] {
          justify-content: center;
          align-items: center;
          margin: 0;
          border-bottom: 2px rgba(0, 0, 0, 0.288) solid;
          transition-duration: 0.2s;
          &[class~="ant-tabs-tab-active"] {
            font-weight: bold;
            border-bottom: 2px #000000 solid;
            [class~="ant-tabs-tab-btn"] {
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
}
